<template>
  <div class="wrapper">

    <right-content>
<!--      <div class="right-content_head">-->
<!--        <el-button @click="exportUser">导出监督岗成员</el-button>-->
<!--      </div>-->
      <div class="right-content_table">
        <span class="title">监督岗名称:({{ sentry_name }})</span>
        <div class="table">
          <my-table
            :tableInfo="tableInfo"
            :colConfigs="colConfigs"
            @currentPage="currentPage"
          >
            <el-table-column
              width="300px"
              align="center"
              slot="option"
              label="操作"
            >
              <template slot-scope="{ row }">
                <div class="option">
                  <el-button icon="iconfont icon-shanchu11" @click="del(row)"
                    >删除</el-button
                  >
                  <el-button
                    icon="iconfont icon-zhongzhi1"
                    @click="resetPwd(row)"
                    >重置密码</el-button
                  >
                </div>
              </template>
            </el-table-column>
          </my-table>
        </div>
      </div>
    </right-content>
  </div>
</template>

<script>
import MyTable from "@/components/MyTable";
import TreeToVliew from "../../commonViews/TreeToVliew";
import RightContent from "../../commonViews/RightContent";
import { sentryUserList, resetPassword, delInformation,exportUser } from "@/api";
import { exportFun } from "@/utils/params";
export default {
  components: { TreeToVliew, MyTable, RightContent },

  async created() {
    const id = this.$route.query.id;
    if (id) {
      await this.getSentrysData({
        sentry_id:id,
        page:1
      });
      this.sentry_id = id;
      this.sentry_name = this.$route.query.name
    }
  },
  methods: {
    currentPage(current) {
      this.getSentrysData({
        sentry_id:this.sentry_id,
        page:current
      })
    },
    // exportUser(){
    //     const param = {
    //       type:1,
    //       sentry_id:this.sentry_id,
    //       group_id:localStorage.getItem("group_id"),
    //     }
    //     exportFun(exportUser,param,"exportUser")
    // },
    async getSentrysData(request) {
      const res = await sentryUserList(request);
      if (res.status === 200) {
        this.tableInfo = res.data.data;
      }
    },
    async del({ id }) {
      try {
        const flag = await this.$open({ msg: "确定删除人员？" });
        if (flag) {
          const res = await delInformation({
            type: 1,
            del_type: 1,
            id,
          });
          if (res.status === 200) {
            this.$success("删除成功");
            await this.getSentrysData(this.sentry_id);
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    resetPwd({ id }) {
      this.$open({
        msg: "确认重置",
        cancelText: "取消重置",
      }).then(async () => {
        try {
          const res = await resetPassword({
            type: 1,
            reset_type: 1,
            id,
          });
          if (res.status === 200) {
            this.$success("重置成功");
          }
        } catch (e) {}
      });
    },
  },
  data() {
    return {
      page:1,
      departmentId: 0,
      sentry_id: "",
      sentry_name:"",
      tableInfo: {},
      colConfigs: [
        { prop: "id", label: "序号", width: "60px" },
        { prop: "name", label: "名称" },
        { prop: "job", label: "职务" },
        { prop: "craft", label: "工种" },
        { prop: "politics_status", label: "政治面貌" },
        { prop: "phone", label: "联系电话" },
        { slot: "option" },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  height: 100%;
  display: flex;
  .right-content_head {
    margin-bottom: 20px;
    button {
      background-color: red;
      color: #fff;
      padding: 10px 25px;
    }
  }
  .right-content_table {
    .table {
      margin: 20px 0;
    }
    button {
      padding: 7px 15px;
      background: #fde5e4;
      border-radius: 4px;
      border: 0;
      font-size: 15px;
      font-family: PingFang SC;
      color: #e32322;
    }
  }
}
</style>
