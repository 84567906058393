<template>
  <left-content>
    <div class="tree">
      <el-tree
        class="filter-tree"
        @node-click="nodeClick"
        :data="treeData"
        default-expand-all
        ref="tree"
      >
      </el-tree>
    </div>
  </left-content>
</template>

<script>
import { getAllChildrenList } from "@/api";
import LeftContent from "./LeftContent";
export default {
  name: "TreeToVliew",
  components: { LeftContent },
  props: {
    //是否显示部门列表
    isDeep: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      treeData: [
        {
          label: JSON.parse(localStorage.getItem("userInfo")).name,
          children: [],
          flag: "group_id",
          id: localStorage.getItem("group_id"),
        },
      ],
    };
  },
  methods: {
    nodeClick(row) {
      const { flag, id,label } = row;
      let requestData = {};
      requestData[flag] = id;
      requestData.page = 1
      requestData.label = label
      this.$emit("request",requestData);
    },

    async renderTreeList(){
      const res = await getAllChildrenList({
      type: localStorage.getItem("group_id"),
    });
    const companys = res.data.data.company;
    const dataTree = (data) => {
      return data.map(c => {
        let tree = {
          label: c.name,
          id: c.id,
          flag: "company_id",
        };
        if (this.isDeep && c.department && c.department.length > 0) {
          tree.children = c.department.map((item) => {
            return  {
              label: item.name,
              id: item.id,
              flag: "department_id",
            };
          });
        }
        return tree;
      });
    }
      this.treeData[0].children = dataTree(companys);
    }
  },

  async activated () {
    await this.renderTreeList();
  },
  async created() {
    this.renderTreeList();
    //获取项目公司下面所有数据
    const firstParams = {
      flag: "group_id",
      id: 1,
      label: JSON.parse(localStorage.getItem("userInfo")).name,
    };
    this.nodeClick(firstParams);
  },
};
</script>

<style lang="scss" scoped>
.filter-tree {
  margin: 15px;
  background-color: #ffffff;
  font-size: 20px;
}
::v-deep .el-tree-node__content{
  height: 30px;
}
::v-deep .el-tree-node__label{
  font-size: 16px;
}
</style>
